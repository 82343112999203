/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react';
import 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';

import adminService from 'services/admin.service';

import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import { useContextHook } from 'use-context-hook';
import { LangConverter, capitalize, getDateObject } from 'helpers/common';
import Select from 'components/atoms/Select';
import GridCol from 'components/atoms/GridCol';
import { SERVICE_TYPES } from 'helpers/constants';

function ServicesForm({ onClose = () => {}, service }) {
  const [form] = useForm();
  const [state, setState] = useState({});
  const [loading, setLoading] = useState(false);
  const { refetch } = useContextHook(AuthContext, ['refetch']);
  const { lang } = useContextHook(AuthContext, { lang: 1 });
  useEffect(() => {
    if (service) {
      const s = { ...service };
      s.providerId = { label: service?.providerId?.providerName, value: service?.providerId?._id };
      if (service?.serviceType) {
        const filteredService = SERVICE_TYPES.find(itm => itm.value === service?.serviceType);
        s.serviceType = filteredService;
      }
      form.setFieldsValue(s);

      setState(s);
    }
  }, [service]);

  const handleProviderSearch = async __ => {
    try {
      const response = await adminService.getServiceProviders({
        page: 1,
        pageSize: 10,
        searchText: __,
        startDate: '',
        endDate: '',
        filterProviders: '',
      });
      const options = response?.serviceProviders?.map(_ => ({
        value: _,
        label: capitalize(_?.providerName),
      }));
      return options;
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
      return [];
    }
  };
  const onSubmit = async data => {
    const payload = {
      ...data,
      providerId: data?.providerId?.value?._id,
      serviceType: data?.serviceType?.value,
    };

    try {
      setLoading(true);
      const res = !service
        ? await adminService.createService(payload)
        : await adminService.updateService(service?._id, payload);
      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: res?.message,
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: ex.message,
      });
    }
  };

  return (
    <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <Grid
        xs={1}
        lg={2}
        colGap={20}
        css={`
          align-items: center;
        `}>
        <Form.Item
          sm
          type="text"
          label={LangConverter('Service Code', lang.value)}
          name="serviceCode"
          placeholder={LangConverter('Service Code', lang.value)}
          rules={[{ required: true, message: LangConverter('Please enter service code', lang.value) }]}>
          <Field />
        </Form.Item>

        <Form.Item
          sm
          type="text"
          label={LangConverter('Service Provider', lang.value)}
          name="providerId"
          open
          async
          defaultOptions
          filterOption={false}
          loadOptions={handleProviderSearch}
          placeholder={LangConverter('Select Service Provider', lang.value)}
          rules={[{ required: true, message: LangConverter('Please select service provider', lang.value) }]}>
          <Select />
        </Form.Item>
        <Form.Item
          sm
          type="select"
          label={LangConverter('Service Type', lang.value)}
          name="serviceType"
          options={SERVICE_TYPES}
          placeholder={LangConverter('Select Service Type', lang.value)}>
          <Select />
        </Form.Item>
        <Form.Item
          type="number"
          sm
          label="Max Attendees"
          name="slots"
          placeholder="1"
          rules={[
            { required: true, message: 'Max Attendees is required' },
            {
              min: 1,
              message: 'Minimum value should be 1',
            },
            {
              max: 200,
              message: 'Maximum value should be less than or equal to 200',
            },
          ]}>
          <Field />
        </Form.Item>
        <Form.Item
          type="number"
          sm
          label={LangConverter('Price', lang.value)}
          name="price"
          placeholder={LangConverter('Price', lang.value)}
          prefix="€"
          rules={[
            {
              required: true,
              message: LangConverter('Please enter price', lang.value),
            },
          ]}>
          <Field />
        </Form.Item>
      </Grid>

      <GridCol xs={1}>
        <Form.Item
          sm
          type="textarea"
          label={LangConverter('Description', lang.value)}
          name="description"
          placeholder={LangConverter('Enter Description', lang.value)}
          rules={[{ required: true, message: LangConverter('Please enter description', lang.value) }]}>
          <Field />
        </Form.Item>
      </GridCol>
      <Button
        disabled={loading}
        loading={loading}
        type="primary"
        htmlType="submit"
        css={`
          margin-top: 20px;
        `}>
        {service ? LangConverter('Update', lang.value) : LangConverter('Create', lang.value)}
      </Button>
    </Form>
  );
}

export default ServicesForm;
