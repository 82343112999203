/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useMemo } from 'react';
import 'styled-components/macro';
import { AuthContext } from 'context/authContext';
import Button from 'components/atoms/Button';
import Grid from 'components/atoms/Grid';

import adminService from 'services/admin.service';

import Field from 'components/molecules/Field';
import Form, { useForm } from 'components/molecules/Form';
import Toast from 'components/molecules/Toast';
import { useContextHook } from 'use-context-hook';
import { LangConverter } from 'helpers/common';
import resellerService from 'services/resellers.service';
import GridCol from 'components/atoms/GridCol';
import DetailsCard from 'components/molecules/DetailsCard';
import Select from 'components/atoms/Select';
import { GENDER_TYPES } from 'helpers/constants';
import Heading from 'components/atoms/Heading';
import { Flex } from 'styles/helpers.styles';
import { format } from 'date-fns';
import ModalContainer from 'components/molecules/ModalContainer';
import SearchStudentTaxCode from '../SearchStudentTaxCode';

function CreateStudent({ onClose = () => {}, student, reseller }) {
  const [form] = useForm();
  const [form2] = useForm();
  const [state, setState] = useState({
    documents: [
      {
        type: 'educationalQualificationDocument',
        url: '',
      },
      {
        type: 'idDocument',
        url: '',
      },
      {
        type: 'preRegistrationDocument',
        url: '',
      },
    ],
  });
  const [foundStudent, setFoundStudent] = useState(null);
  const [loading, setLoading] = useState(false);
  const { refetch, user } = useContextHook(AuthContext, ['refetch', 'user']);
  const { lang } = useContextHook(AuthContext, { lang: 1 });
  const isAdmin = user?.role_type?.includes('ADMIN') || user?.role_type?.includes('JH_USER');

  const updateDocuments = (type, url) => {
    const newDocuments = state.documents.map(document => {
      if (document?.type === type) {
        return {
          ...document,
          url,
        };
      }
      return document;
    });
    setState(prev => ({ ...prev, documents: newDocuments }));
    form.setFieldsValue({ documents: newDocuments });
  };

  const getDocumentByType = type => state.documents.find(document => document?.type === type)?.url ?? null;

  const handleSetStudent = existingStudent => {
    const s = { ...existingStudent };
    const timeSplit = existingStudent.dateOfBirth.split('-');
    const year = timeSplit[0];
    const month = timeSplit[1];
    const day = timeSplit[2].split('T')[0];
    s.dateOfBirth = `${day}/${month}/${year}`;
    s.selectedDate = new Date(`${year}-${month}-${day}`);
    s.originalDob = existingStudent.dateOfBirth;
    if (existingStudent.gender) {
      const filteredGender = GENDER_TYPES.find(g => g.value === existingStudent.gender);
      s.gender = filteredGender;
    }
    if (existingStudent.resellers?.length && isAdmin) {
      const resellers = existingStudent?.resellers?.map(res => ({
        label: res.email,
        value: res._id,
      }));
      s.resellers = resellers;
      // saving to match codice fiscale
      setFoundStudent(s);
    }

    form.setFieldsValue(s);
    setState(s);
  };

  useEffect(() => {
    if (student) {
      handleSetStudent(student);
    }
  }, [student]);

  useEffect(() => {
    if (reseller) {
      const s = { resellerEmail: reseller?.email };

      form.setFieldsValue(s);
      setState(s);
    }
  }, []);

  const onSubmit = async data => {
    const payload = { ...data, roleType: 'STUDENT', gender: data.gender?.value };
    if (typeof payload?.dateOfBirth === 'string' && payload?.dateOfBirth?.includes('/')) {
      payload.dateOfBirth = state.originalDob;
    }
    if (reseller?.email) {
      delete payload.resellerEmail;
    }
    if (foundStudent?.codiceFiscale === payload.codiceFiscale) {
      payload.isExistingStudent = true;
    }
    if (isAdmin) {
      payload.resellers = payload?.resellers?.length ? payload?.resellers?.map(res => res.value) : [];
    }

    try {
      setLoading(true);
      const res =
        reseller || (isAdmin && !student)
          ? await adminService.createStudent(reseller?._id, payload)
          : isAdmin && student
          ? await adminService.updateStudent(student?._id, payload)
          : !student
          ? await resellerService.createStudents(payload)
          : await resellerService.updateStudents(student._id, payload);
      refetch();
      onClose();
      setLoading(false);
      Toast({
        type: 'success',
        message: res?.message,
      });
    } catch (ex) {
      setLoading(false);
      Toast({
        type: 'error',
        message: LangConverter(ex.message, lang.value),
      });
    }
  };
  const handleImportData = studentData => {
    handleSetStudent(studentData);
  };
  const handleResellerSearch = async __ => {
    try {
      const response = await adminService.getResellers({
        page: 1,
        pageSize: 10,
        searchText: __,
        startDate: '',
        endDate: '',
        filterProviders: '',
      });
      const options = response?.resellers?.map(_ => ({
        value: _?._id,
        label: _?.email,
      }));

      return options;
    } catch (ex) {
      Toast({
        type: 'error',
        message: ex.message,
      });
      return [];
    }
  };

  // const handleSelectReseller = ({ target: { value } }) => {
  //   let resellers = null;
  //   // Check if empty value selected at last postion
  //   const lastValue = value[value.length - 1];

  //   if (lastValue?.value) {
  //     resellers = value?.filter(val => val?.value);
  //   } else {
  //     resellers = [{ label: 'No Reseller', value: '' }];
  //   }

  //   form.setFieldsValue({ resellers });
  // };
  return (
    <Form form={form} onSubmit={onSubmit} onTouched={_ => setState(__ => ({ ...__, ..._ }))}>
      <DetailsCard transparent css="margin-bottom: 30px;">
        {!student ? (
          <div css="display: flex; justify-content: end; align-items: center;">
            <ModalContainer
              md
              title={LangConverter('Search Student by Tax Code', lang.value)}
              btnComponent={({ onClick }) => (
                <Button sm type="primary" width={200} onClick={onClick}>
                  {LangConverter('Search By Tax Code', lang.value)}
                </Button>
              )}
              content={({ onClose: onCloseModal }) => (
                <SearchStudentTaxCode onClose={onCloseModal} onImportData={handleImportData} />
              )}
            />
          </div>
        ) : null}
        <Flex justify="center" align="middle">
          <Heading level={4}>Personal Information</Heading>
        </Flex>
        {isAdmin ? (
          <GridCol xs={12}>
            <Form.Item
              sm
              label={LangConverter('Select Reseller', lang.value)}
              name="resellers"
              open
              async
              isMulti
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
              defaultOptions
              // onChange={handleSelectReseller}
              filterOption={false}
              loadOptions={handleResellerSearch}
              placeholder={LangConverter('Select Resellers', lang.value)}
              // rules={[{ required: true, message: LangConverter('Please select a option', lang.value) }]}
            >
              <Select />
            </Form.Item>
          </GridCol>
        ) : null}
        <Grid
          xs={1}
          lg={3}
          colGap={20}
          css={`
            align-items: center;
          `}>
          <Form.Item
            sm
            type="text"
            label={LangConverter('First Name', lang.value)}
            name="firstName"
            placeholder={LangConverter('First Name', lang.value)}
            rules={[{ required: true, message: LangConverter('Please enter first name', lang.value) }]}>
            <Field />
          </Form.Item>
          <Form.Item
            sm
            type="text"
            label={LangConverter('Last Name', lang.value)}
            name="lastName"
            placeholder={LangConverter('Last Name', lang.value)}
            rules={[{ required: true, message: LangConverter('Please enter last name', lang.value) }]}>
            <Field />
          </Form.Item>
          <Form.Item
            sm
            type="select"
            label="Gender"
            name="gender"
            options={GENDER_TYPES}
            placeholder="Select Gender"
            rules={[{ required: true, message: 'Please select Gender' }]}>
            <Select />
          </Form.Item>
          <Form.Item
            sm
            name="dateOfBirth"
            label={LangConverter('Date of Birth', lang.value)}
            selected={state?.selectedDate}
            onChange={date => {
              const formattedDate = format(new Date(date.target.value), 'yyyy-MM-dd'); // Strip time
              form.setFieldsValue({ dateOfBirth: formattedDate ?? '' });
              setState(prev => ({ ...prev, selectedDate: date.target.value }));
            }}
            isClearable
            prefix={<i className="material-icons-outlined">date_range</i>}
            placeholderText={LangConverter('Select Date Of Birth', lang.value)}
            type="datepicker"
            rules={[{ required: true, message: LangConverter('Date Of Birth is required', lang.value) }]}>
            <Field />
          </Form.Item>
          <Form.Item
            type="text"
            sm
            label={LangConverter('Country of Birth', lang.value)}
            name="countryOfBirth"
            placeholder={LangConverter('Country of Birth', lang.value)}
            rules={[
              {
                required: true,
                message: LangConverter('Country of birth is required', lang.value),
              },
            ]}>
            <Field />
          </Form.Item>
          <Form.Item
            type="text"
            sm
            label={LangConverter('Province of Birth', lang.value)}
            name="provinceOfBirth"
            placeholder={LangConverter('Province of Birth', lang.value)}
            rules={[
              {
                required: true,
                message: LangConverter('Province of birth is required', lang.value),
              },
            ]}>
            <Field />
          </Form.Item>
          <Form.Item
            type="text"
            sm
            label={LangConverter('Place of Birth', lang.value)}
            name="placeOfBirth"
            placeholder={LangConverter('Place of Birth', lang.value)}
            rules={[
              {
                required: true,
                message: LangConverter('Place of birth is required', lang.value),
              },
            ]}>
            <Field />
          </Form.Item>
          <Form.Item
            type="text"
            sm
            label={LangConverter('Tax Code', lang.value)}
            name="codiceFiscale"
            placeholder={LangConverter('Codice Fiscale', lang.value)}
            rules={[
              { required: true, message: LangConverter('Please enter tax code', lang.value) },
              {
                pattern: /^(?=.*[A-Z])(?=.*\d)[A-Z0-9]{16}$/,
                message: LangConverter('Tax code must be 16 characters', lang.value),
              },
              { changeRegex: 'codice_fiscale' },
            ]}>
            <Field />
          </Form.Item>
        </Grid>
      </DetailsCard>

      <DetailsCard transparent css="margin-bottom: 30px;">
        <Flex justify="center" align="middle">
          <Heading level={4}>Residence</Heading>
        </Flex>
        <Grid
          xs={1}
          lg={3}
          colGap={20}
          css={`
            align-items: center;
          `}>
          <Form.Item
            type="text"
            sm
            label={LangConverter('Postal Code', lang.value)}
            name="postalCode"
            placeholder={LangConverter('Postal Code', lang.value)}
            rules={[
              {
                required: true,
                message: LangConverter('Postal Code is required', lang.value),
              },
            ]}>
            <Field />
          </Form.Item>
          <Form.Item
            type="text"
            sm
            label={LangConverter('City Code', lang.value)}
            name="cityCode"
            placeholder={LangConverter('City Code', lang.value)}
            rules={[
              {
                required: true,
                message: LangConverter('City code is required', lang.value),
              },
            ]}>
            <Field />
          </Form.Item>
          <Form.Item
            type="text"
            sm
            label={LangConverter('Country', lang.value)}
            name="country"
            placeholder={LangConverter('Country', lang.value)}
            rules={[
              {
                required: true,
                message: LangConverter('Country is required', lang.value),
              },
            ]}>
            <Field />
          </Form.Item>
          <Form.Item
            type="text"
            sm
            label={LangConverter('Province', lang.value)}
            name="province"
            placeholder={LangConverter('Province', lang.value)}
            rules={[
              {
                required: true,
                message: LangConverter('Province is required', lang.value),
              },
            ]}>
            <Field />
          </Form.Item>
          <Form.Item
            type="text"
            sm
            label={LangConverter('Address', lang.value)}
            name="address"
            placeholder={LangConverter('Address', lang.value)}
            rules={[
              {
                required: true,
                message: LangConverter('Address is required', lang.value),
              },
            ]}>
            <Field />
          </Form.Item>
          <Form.Item
            type="text"
            sm
            label={LangConverter('House Number', lang.value)}
            name="houseNumber"
            placeholder={LangConverter('House Number', lang.value)}
            rules={[
              {
                required: true,
                message: LangConverter('House Number is required', lang.value),
              },
            ]}>
            <Field />
          </Form.Item>
        </Grid>
      </DetailsCard>

      <DetailsCard transparent css="margin-bottom: 30px;">
        <Flex justify="center" align="middle">
          <Heading level={4}>Contact Info</Heading>
        </Flex>
        <Grid
          xs={1}
          lg={3}
          colGap={20}
          css={`
            align-items: center;
          `}>
          <Form.Item
            type="email"
            sm
            label={LangConverter('Email', lang.value)}
            name="email"
            placeholder={LangConverter('E-mail', lang.value)}
            rules={[
              {
                required: true,
                message: LangConverter('Email is required.', lang.value),
              },
              { email: true },
            ]}>
            <Field />
          </Form.Item>
          {!student && (
            <Form.Item
              type="password"
              sm
              label={LangConverter('Password', lang.value)}
              name="password"
              placeholder={LangConverter('Password', lang.value)}
              rules={[
                {
                  required: true,
                },
                { password: true },
              ]}>
              <Field />
            </Form.Item>
          )}

          {reseller?.email && (
            <Form.Item
              type="email"
              sm
              label={LangConverter('Reseller', lang.value)}
              name="resellerEmail"
              placeholder={LangConverter('E-mail', lang.value)}
              value={reseller?.email}
              disabled
              rules={[
                {
                  required: true,
                  message: LangConverter('Email is required.', lang.value),
                },
                { email: true },
              ]}>
              <Field />
            </Form.Item>
          )}
          <Form.Item
            type="text"
            sm
            label={LangConverter('Phone Number', lang.value)}
            name="phoneNumber"
            placeholder="(XXX) XXX-XXXX"
            rules={[
              {
                required: true,
                message: LangConverter('Phone Number is required', lang.value),
              },
              { changeRegex: 'phone_number' },
            ]}>
            <Field />
          </Form.Item>

          <Form.Item
            type="text"
            sm
            label={LangConverter('Mobile Number', lang.value)}
            name="mobileNumber"
            placeholder="(XXX) XXX-XXXX"
            rules={[{ changeRegex: 'phone_number' }]}>
            <Field />
          </Form.Item>
        </Grid>
      </DetailsCard>

      <DetailsCard transparent css="margin-bottom: 30px;">
        <Flex justify="center" align="middle">
          <Heading level={4}>Other Info</Heading>
        </Flex>
        <Grid
          xs={1}
          lg={3}
          colGap={20}
          css={`
            align-items: center;
          `}>
          <Form.Item
            type="text"
            sm
            label={LangConverter('Year of Pre Registration', lang.value)}
            name="yearOfEnrollment"
            placeholder={LangConverter('Year of Pre Registration', lang.value)}>
            <Field />
          </Form.Item>
          <Form.Item
            type="text"
            sm
            label={LangConverter('EIPASS Standard Code', lang.value)}
            name="eipassStandardCode"
            placeholder={LangConverter('EIPASS Standard Code', lang.value)}>
            <Field />
          </Form.Item>
        </Grid>
      </DetailsCard>

      {/* <Form.Item
          type="number"
          sm
          label={LangConverter('Partita Iva', lang.value)}
          name="partitaIva"
          placeholder="123456789"
          rules={[
            {
              pattern: /^[0-9]{11}$/,
              message: LangConverter('Enter a valid 11 digits Partita Iva', lang.value),
            },
          ]}>
          <Field />
        </Form.Item> */}

      {/* <Form.Item
          type="text"
          sm
          label={LangConverter('Qualification', lang.value)}
          name="qualification"
          placeholder={LangConverter('Qualification', lang.value)}>
          <Field />
        </Form.Item> */}
      <DetailsCard transparent css="margin-bottom: 30px;">
        <Flex justify="center" align="middle">
          <Heading level={4}>Attachments</Heading>
        </Flex>
        <Grid xs={1} md={8} gap={14}>
          <GridCol xs={1} sm={4}>
            <Form.Item
              type="chooseFile"
              label={LangConverter('Upload ID Document', lang.value)}
              name="idDocument"
              pdf
              value={getDocumentByType('idDocument')}
              onChange={({ target }) => {
                updateDocuments('idDocument', target?.value);
              }}>
              <Field />
            </Form.Item>
          </GridCol>
          <GridCol xs={1} sm={4}>
            <Form.Item
              type="chooseFile"
              label={LangConverter('Upload Pre-Registration', lang.value)}
              name="preRegistrationDocument"
              pdf
              value={getDocumentByType('preRegistrationDocument')}
              onChange={({ target }) => {
                updateDocuments('preRegistrationDocument', target?.value);
              }}>
              <Field />
            </Form.Item>
          </GridCol>
          <GridCol xs={1} sm={4}>
            <Form.Item
              type="chooseFile"
              label={LangConverter('Upload Educational Qualification Certification', lang.value)}
              name="educationalQualificationDocument"
              pdf
              value={getDocumentByType('educationalQualificationDocument')}
              onChange={({ target }) => {
                updateDocuments('educationalQualificationDocument', target?.value);
              }}>
              <Field />
            </Form.Item>
          </GridCol>
        </Grid>
      </DetailsCard>
      <Flex justify="center" align="middle">
        <Button
          disabled={loading}
          loading={loading}
          type="primary"
          htmlType="submit"
          width={300}
          css={`
            margin-top: 20px;
          `}>
          {student ? LangConverter('Update', lang.value) : LangConverter('Create', lang.value)}
        </Button>
      </Flex>
    </Form>
  );
}

export default CreateStudent;
